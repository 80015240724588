import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SET_ACCESS_TOKEN } from "../../store/types";
import { ROUTES, baseUrl } from "../../lib/consts";
import "../../styles/style2.scss";
import withLoader from "../Loader/withLoader";

// import images from "./images/image.png";

const SignIn: React.FC = (props: any) => {
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOtpScreenVisible, setOtpScreenVisible] = useState(false);
  const [resendOTPTime, setResendOTPTime] = useState(0);

  const [errorMessage, setErrorMessage] = useState("");

  const accessDetails = useSelector((state: any) => state.authReducer);

  
  
  useEffect(() => {
    resendOTPTime > 0 &&
    setTimeout(() => setResendOTPTime(resendOTPTime - 1), 1000);
  }, [resendOTPTime]);
  
  // if the user is logged in and opens login page redirect to home
  if(!!accessDetails.accessToken){
   return <Navigate to={ROUTES.HOME} />;
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleOTPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 6) return;
    setOTP(event.target.value);
  };

  const isEmail = () => {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(email)) {
      return false;
    }
    return true;
  };

  const handleEmailSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    // dispatch({
    //   type: SET_ACCESS_TOKEN,
    //   payload: "dummy_access_token"
    // });

    // navigate(ROUTES.HOME);
    // return true;

    if (resendOTPTime) return;
    setResendOTPTime(60);
    if (!isEmail()) {
      setErrorMessage("Enter valid email");
      return;
    }
    const userKey = "";
    const data = {
      userKey,
      email
    };
    setErrorMessage("");
    props
      ?.postData(`${baseUrl}/users/getEmail`, data)
      .then((res: any) => {
        if (res?.statusCode === 200) {
          setErrorMessage("");
          setOtpScreenVisible(true);
          return true;
        }
        // else if (isTestMode) {
        //   console.log("test mode");
        //   setOtpScreenVisible(true);
        //   return true;
        // }
        else {
          setErrorMessage(
            res?.message || "Something went wrong. Please try again"
          );
          // document.getElementById('error').innerHTML =
          return false;
        }
      })
      .catch((err: any) => {
        setErrorMessage(
          err.message || "Something went wrong. Please try again"
        );
      });
    return false;
  };

  const handleOTPSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    console.log("otp submit clicked", email);
    if (!otp) return;
    if (otp.length !== 6) {
      setErrorMessage("OTP is a 6 digit number");
      return;
    }

    const userKey = "";
    const data = {
      userKey: userKey,
      otp,
      email
    };
    setErrorMessage("");
    props
      .postData(`${baseUrl}/users/verifyOTP`, data)
      .then((res: any) => {
        const { token } = res
        if (res?.statusCode === 200) {
          // submit otp and if valid otp
          dispatch({
            type: SET_ACCESS_TOKEN,
            payload: token
          });
          localStorage.setItem("access_token", token);
          navigate(ROUTES.HOME);
          return true;
        } else {
          setErrorMessage(
            res?.message || "Something went wrong. Please try again"
          );
          // document.getElementById('error').innerHTML =
          return false;
        }
      })
      .catch((err: any) => {
        setErrorMessage(err || "Something went wrong. Please try again");
      });
    return false;
  };

  return (
    <>
      <div className="mainContainer">
        <div className="overview">
          <div className="overview-logo-wrapper">
            <img className="overviewlogo" src="./images/Bigcitylogo.png" />
          </div>
          <p className="overviewtxt">
            Over the last 10 years – BigCity has grown from a four-member
            boutique agency with a handful of clients into a team of 100+ people
            <span className="mobile-hide">
              {" "}
              - which is our power house of Ideas and Execution. We are
              currently working with over 200+ blue chip brands pan India.
            </span>
          </p>
        </div>
        <div className="formContainer">
          <h2>{isOtpScreenVisible ? "Enter OTP" : "SIGN IN"}</h2>
          <h3 className="h3">Simplify access, maximize productivity.</h3>
          {!isOtpScreenVisible ? (
            <form onSubmit={handleEmailSubmit}>
              <input
                type="string"
                id="email"
                placeholder="ENTER YOUR MAIL ID"
                value={email}
                onChange={handleEmailChange}
              />
              <div className="error-message">{errorMessage}</div>
              <input type="submit" value="SUBMIT" />
            </form>
          ) : (
            <form onSubmit={handleOTPSubmit}>
              <input
                type="number"
                id="otp"
                placeholder="ENTER OTP"
                value={otp}
                onChange={handleOTPChange}
              />
              <div className="error-message">{errorMessage}</div>
              <div>
                <div className="resend-otp-container">
                  <div className="center-content">
                    {resendOTPTime ? (
                      <div className="circle center-content timer">
                        {resendOTPTime}
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={`resend-otp center-content ${
                      resendOTPTime ? "disabled" : ""
                    }`}
                    onClick={handleEmailSubmit}
                  >
                    Resend OTP
                  </div>
                </div>
              </div>
              <input type="submit" value="SUBMIT" />
            </form>
          )}
        </div>
      </div>
      <div className="steps-footer-wrapper">
        <div className="stepsContainer">
          <div className="step-wrapper  step-1">
            <div className="step">
              <img src="./images/login.png" alt="Enter email" />
            </div>
            <p className="step-description">Enter Mail id To get a OTP</p>
          </div>
          <div className="step-wrapper  step-2">
            <div className="step">
              <img src="./images/enterotp.png" alt="Enter OTP" />
            </div>
            <p className="step-description">Enter OTP</p>
          </div>
          <div className="step-wrapper  step-3">
            <div className="step">
              <img src="./images/portfolio.png" alt="Explore portfolio" />
            </div>
            <p className="step-description">Explore our portfolio</p>
          </div>
        </div>
        <footer className="mobile-hide">
          <p>
            As an agency, our experience stems from 5000+ campaigns executed for
            over 200 brands with briefs spanning tactical marketing, promotions,
            loyalty, engagement, partnerships, contests and fulfilment.
          </p>
        </footer>
      </div>
    </>
  );
};

export default withLoader(SignIn);
