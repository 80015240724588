import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { ROUTES } from "./lib/consts";
import PrivateRoute from "./helpers/PrivateRoute";
// import "./styles/style.scss";
import SignIn from "./components/SignIn/SignIn";
import Header from "./Header";
import Home from "./components/Home/Home";
import { useEffect } from "react";
import { on } from "events";


function App() {
  const navigate = useNavigate()
  window.onpopstate = () => {
    navigate("/")
  }
  window.onload = () => {
    navigate("/")
  }
  

  return (
    
    <div className="App">
    <Header />
    
      <Routes>
        <Route path={ROUTES.LOGIN} element={<SignIn />} />
        <Route
          path={ROUTES.PRIVATE_ROUTE}
          element={<PrivateRoute>
            <p>Your Private route component here</p>
            
          </PrivateRoute>} />
          <Route
          path={ROUTES.HOME}
          element={<PrivateRoute>
            <Home />
            </PrivateRoute>} />
      </Routes>
      <ToastContainer position="bottom-center" hideProgressBar={true} />
    </div>
  );
}

export default App;
