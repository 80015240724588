import React, { useState } from "react";
import postData from "../../helpers/postData";
import HashLoader from "react-spinners/HashLoader";

const withLoader = (WrappedComponent: React.ElementType) => {
  return (props: any) => {
    const [isLoading, setIsLoading] = useState(false);

    const postDataWithLoader = async (url: string, data: any) => {
      setIsLoading(true);

      try {
        // Your API call using the postData function
        const response = await postData(url, data);
        setIsLoading(false);
        return response;
      } catch (error) {
        // Handle any error that occurs during the API call
        setIsLoading(false);
        console.error("Error:", error);
        throw error;
      }
    };

    return (
      <div>
        {isLoading && (
          <div className="full-screen-pop-over">
            <div>
              <HashLoader color="#345F99" />
            </div>
          </div>
        )}
        <WrappedComponent {...props} postData={postDataWithLoader} />
      </div>
    );
  };
};

export default withLoader;
