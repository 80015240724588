import React, { useState } from "react";
import "./styles/style.scss";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SET_ACCESS_TOKEN } from "./store/types";
import { ROUTES } from "./lib/consts";
import SideNavigation from "./SidNavigation";

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSideNavVisible, setIsSideNavVisible] = useState(false);
  const accessDetails = useSelector((state: any) => state.authReducer);

  const logout = () => {
    localStorage.removeItem("access_token");
    dispatch({
      type: SET_ACCESS_TOKEN,
      payload: null
    });
    navigate(ROUTES.LOGIN);
    setIsSideNavVisible(false);
  };
  const isLoggedIn = !!accessDetails.accessToken;
  return (
    <>
      <header>
        <img className="logo-img" src="./images/logo.png" alt="logo" />
        <div>
          <div id="menu-list">
            <button className="btn">
              <a href="https://www.bigcity.in/aboutus/" target="_blank">About Us</a>
            </button>
            <button className="btn">
              <a href="https://www.bigcity.in/blogs/" target="_blank">Blogs</a>
            </button>
            <button className="btn">
              <a href="https://www.bigcity.in/client-tele/" target="_blank">Clients</a>
            </button>
            <button className="btncont">
              <a href="https://www.bigcity.in/contact/" target="_blank">Contact</a>
            </button>
            {isLoggedIn && (
              <button className="btn logout" onClick={logout}>
                Logout
              </button>
            )}
          </div>
          <div
            className="menu"
            onClick={() => {
              console.log("asdfasdf");
              setIsSideNavVisible(true);
            }}
          >
            <img
              className="button-icon"
              src={"./images/Vector.png"}
              alt="Menu"
            />
          </div>
        </div>
        <img className="button-img" src="./images/Vector.png" alt="button" />
      </header>
      <SideNavigation
        isOpen={isSideNavVisible}
        onClose={() => setIsSideNavVisible(false)}
      />
    </>
  );
};

export default Header;
