import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_ACCESS_TOKEN } from "./store/types";
import { ROUTES } from "./lib/consts";
import { useNavigate } from "react-router-dom";

export default function SideNavigation({ isOpen, onClose }: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accessDetails = useSelector((state: any) => state.authReducer);

  const logout = () => {
    localStorage.removeItem("access_token");
    dispatch({
      type: SET_ACCESS_TOKEN,
      payload: null
    });
    navigate(ROUTES.LOGIN);
    onClose();
  };
  const isLoggedIn = !!accessDetails.accessToken;

  return (
    <>
      {
        <div className={`side-nav-contianer ${isOpen ? "open" : "close"}`}>
          <div className="close-sidenav" onClick={onClose}>
            <img
              className="button-icon"
              src={"./images/Group83.png"}
              alt="Close"
            />
          </div>
          <div className="nav-list">
            <button className="btn">
              <a href="https://www.bigcity.in/aboutus/" target="_blank">About Us</a>
            </button>
            <button className="btn">
              <a href="https://www.bigcity.in/blogs/" target="_blank">Blogs</a>
            </button>
            <button className="btn">
              <a href="https://www.bigcity.in/client-tele/" target="_blank">Clients</a>
            </button>
            <button className="btn">
              <a href="https://www.bigcity.in/contact/" target="_blank">Contact</a>
            </button>
          </div>
          {isLoggedIn && (
            <div className="logout-wrapper">
              <button onClick={logout}>
                <span>Log out</span>
                <img src="./images/logout.png" alt="logout" />
              </button>
            </div>
          )}
        </div>
      }
    </>
  );
}
