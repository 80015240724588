import React, { useState, useEffect, useCallback, useRef } from "react";

interface FilterProps {
  industryList: string[];
  platformList: string[];
  onChangeCallback: (filterSelected: Filter[]) => void;
}
export interface Filter {
  value: string;
  type: string;
}

export default function Filters({
  industryList,
  platformList,
  onChangeCallback = (filterSelected: Filter[]) => {}
}: FilterProps) {
  const [isFilterContainerVisible, setIsFilterContainerVisible] = useState(
    false
  );
  const [isFilterBtnVisible, setIsFilterBtnVisible] = useState(true);
  const [selectedFilterType, setSelectedFilterType] = useState("");
  const [selectedFilter, setSelectedFilter] = useState<Filter[]>([]);
  const scrollPos = useRef(0);

  const handleScroll = useCallback(() => {
    const currentScrollPos =
      window.scrollY || document.documentElement.scrollTop;
    // console.log("scrollPos", scrollPos, currentScrollPos);
    if (currentScrollPos > scrollPos.current + 50) {
      // Scrolled down by at least 50 pixels
      setIsFilterBtnVisible(false);
      // console.log("settingScrollPos", currentScrollPos);
      scrollPos.current = currentScrollPos;
    } else if (currentScrollPos < scrollPos.current - 50) {
      // Scrolled up by at least 50 pixels
      setIsFilterBtnVisible(true);
      scrollPos.current = currentScrollPos;
    }
  }, [scrollPos]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onFilterSelect = (filter: string, type: string) => {
    // console.log(selectedFilter);
    if (
      selectedFilter
        .map((selectedFilter) => selectedFilter.value)
        .indexOf(filter) !== -1
    )
      return;
    setSelectedFilter((selectedfilterList) => [
      ...selectedfilterList,
      { value: filter, type: type }
    ]);
    onChangeCallback([...selectedFilter, { value: filter, type: type }]);
    setIsFilterContainerVisible(false);
  };
  const removeFilter = (itemToRemove: Filter) => {
    const updatedItems = selectedFilter.filter(
      (item) =>
        !(item.value === itemToRemove.value && item.type === itemToRemove.type)
    );
    setSelectedFilter(updatedItems);
    onChangeCallback(updatedItems);
  };

  const onFilterButtonClick = (filterType: string) => {
    setIsFilterContainerVisible(!isFilterContainerVisible);
    setSelectedFilterType(filterType);
  };

  return (
    <>
      {!!selectedFilter?.length && (
        <div className="applied-filter-container">
          {selectedFilter.map((filter) => (
            <div className="applied-filter-item">
              <div className="flex-wrapper">
                <div className="filter-text">{filter.value}</div>
                <div onClick={() => removeFilter(filter)}>
                  <img
                    src="./images/iconoircancel.png"
                    alt="close"
                    width="24px"
                    height="24px"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div
        className="filter-container"
        style={{
          display:
            isFilterContainerVisible && isFilterBtnVisible ? "block" : "none"
        }}
      >
        <div className="filter-wrapper">
          {selectedFilterType === "Industry" &&
            industryList.map((filterIndustryName) => (
              <div
                className="filter-item"
                key={filterIndustryName}
                onClick={() => onFilterSelect(filterIndustryName, "industry")}
              >
                {filterIndustryName}
              </div>
            ))}
          {selectedFilterType === "Platform" &&
            platformList.map((filterPlatformName: string) => (
              <div
                className="filter-item"
                key={filterPlatformName}
                onClick={() => onFilterSelect(filterPlatformName, "platform")}
              >
                {filterPlatformName}
              </div>
            ))}
        </div>
      </div>
      <div className={`filter-buttons ${!isFilterBtnVisible ? "hidden" : ""}`}>
        <div className="filter-left">
          <span>Filter</span>
          <img
            className="filter-img"
            src="./images/filterbtn.png"
            alt="Filter Button"
          />
        </div>
        <button
          style={{
            background:
              isFilterContainerVisible && selectedFilterType === "Industry"
                ? "#E3E735"
                : "white"
          }}
          onClick={() => onFilterButtonClick("Industry")}
        >
          <img
            className="button-icon"
            src={
              isFilterContainerVisible && selectedFilterType === "Industry"
                ? "./images/Vector-tick.png"
                : "./images/Vector4.png"
            }
            alt="Industry Icon"
          />
          <span>INDUSTRY</span>
        </button>
        <button
          style={{
            background:
              isFilterContainerVisible && selectedFilterType === "Platform"
                ? "#E3E735"
                : "white"
          }}
          onClick={() => onFilterButtonClick("Platform")}
        >
          <img
            className="button-icon"
            src={
              isFilterContainerVisible && selectedFilterType === "Platform"
                ? "./images/Vector-tick.png"
                : "./images/Vector4.png"
            }
            alt="Platform Icon"
          />
          <span>PLATFORM</span>
        </button>
      </div>
    </>
  );
}
