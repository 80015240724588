import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash.debounce";
import "../../styles/style.scss";
import Filters, { Filter } from "./Filters";
import { baseUrl } from "../../lib/consts";
import withLoader from "../Loader/withLoader";
import  icon from "../../images/icon.svg"

interface Product {
  appId: number;
  appTitle: string;
  imageURL: string;
  description: string;
  tags: string[];
  industry: string[];
  category: string[];
  appLink: string;
  needUniqueCode: boolean;
}

function Home(props: any) {
  const { postData } = props;
  const [products, setProducts] = useState<Product[]>([]);
  const [filtersSelected, setFiltersSelected] = useState<Filter[]>();
  const [errorMessage, setErrorMessage] = useState("");
  const [isUniqueCodeVisible, setUniqueCodeVisible] = useState(false);
  const [uniqueCodes, setUniqueCodes] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  // const [loading, setLoading] = useState<boolean>(false);


  useEffect(() => {
    const fetchApps = () => {
      const userKey = "1234";
      const data = {
        userKey
      };
      postData(`${baseUrl}/users/showApps`, data)
        .then((res: any) => {
          if (res?.statusCode === 200) {
            setProducts(res?.appList);
            setFilteredProducts(res?.appList); // Initialize filtered products
          } else {
            setErrorMessage(
              res?.message || "Something went wrong. Please try again"
            );
            return false;
          }
        })
        .catch((err: any) => {
          setErrorMessage(err || "Something went wrong. Please try again");
        });
    };
    fetchApps();
  }, []);

  const onFilterChange = (filterSelected: Filter[]) => {
    setFiltersSelected(filterSelected);
    console.log(filterSelected);
  };

  const showUniqueCodes = (appId: number) => {
    document.body.style.overflow = "hidden";
    postData(`${baseUrl}/users/getUniqueCodes`, { appId })
      .then((res: any) => {
        if (res?.statusCode === 200) {
          setUniqueCodes(res?.codes);
        } else {
          setErrorMessage(
            res?.message || "Something went wrong. Please try again"
          );
          return false;
        }
      })
      .catch((err: any) => {
        setErrorMessage(err || "Something went wrong. Please try again");
      });
    setUniqueCodeVisible(true);
  };

  const hideUniqueCodes = () => {
    document.body.style.overflow = "unset";
    setUniqueCodeVisible(false);
  };

  const onUniqueCodeCopy = (e: any, uniqueCode: string) => {
    navigator.clipboard.writeText(uniqueCode);
    console.log(e.target);
    e.target.classList.add("blink");
    setTimeout(() => {
      e.target.classList.remove("blink");
    }, 600);
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const getIndustriesList = () => {
    const industriesSet = new Set<string>();
    products.forEach((product) => {
      product.industry.forEach((industry) => {
        industriesSet.add(industry.trim());
      });
    });
    return Array.from(industriesSet);
  };

  const getPlatformsList = () => {
    const industriesSet = new Set<string>();
    products.forEach((product) => {
      product.category.forEach((industry) => {
        industriesSet.add(industry.trim());
      });
    });
    return Array.from(industriesSet);
  };

  // Create a debounced function to handle search
  const debouncedSearch = useCallback(
    debounce((query) => {
      setFilteredProducts(
        products.filter((product) =>
          product.appTitle.toLowerCase().includes(query.toLowerCase())
        )
      );
    }, 500), // Adjust the debounce time as needed
    [products]
  );

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const filteredAndFilteredProducts = filteredProducts
    .filter(
      (product) =>
        (!filtersSelected?.filter((filter) => filter.type === "industry")
          ?.length ||
          product.industry
            ?.map((industry) => industry.toLocaleLowerCase())
            .some((product) =>
              filtersSelected
                .filter((filter) => filter.type === "industry")
                .map((filter) => filter.value.toLocaleLowerCase())
                .includes(product.toLocaleLowerCase())
            )) &&
        (!filtersSelected?.filter((filter) => filter.type === "platform")
          ?.length ||
          product.category
            ?.map((category) => category.toLocaleLowerCase())
            .some((product) =>
              filtersSelected
                .filter((filter) => filter.type === "platform")
                .map((filter) => filter.value.toLocaleLowerCase())
                .includes(product.toLocaleLowerCase())
            ))
  );
  console.log('filteredAndFilteredProducts', filteredAndFilteredProducts)

  return (
    <>
      <Filters
        onChangeCallback={onFilterChange}
        industryList={getIndustriesList()}
        platformList={getPlatformsList()}
      />
      <section className="our-works-container">
        <h1 className="our-works">OUR WORKS</h1>
        <h3 className="our-works">
          Discover the full potential of creativity and professional skills as
          you explore my portfolio. Get ready to be impressed with my dazzling
          designs and incredible attention to detail. From stunning graphics to
          breathtaking animations, I've got it all covered. Welcome to my
          portfolio!
        </h3>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search by Title"
            value={searchQuery}
            onChange={handleSearchInput}
            className="search-input"
            // src={icon}
          />
          <span className="search-icon">
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000000" viewBox="0 0 256 256"><path d="M228.24,219.76l-51.38-51.38a86.15,86.15,0,1,0-8.48,8.48l51.38,51.38a6,6,0,0,0,8.48-8.48ZM38,112a74,74,0,1,1,74,74A74.09,74.09,0,0,1,38,112Z"></path></svg> */}
          </span>
        </div>
      </section>
      <div className="product-container">
        {filteredAndFilteredProducts.length > 0 ? (
          filteredAndFilteredProducts.map((product) => (
            <div className="product-card" key={product.appId+product.appTitle}>
              <div
                className="product-img"
                style={{ backgroundImage: `url(${product.imageURL})` }}
              ></div>
              <div className="product-info">
                <div className="tag-container">
                  {product.tags.map((tag) => (
                    <div className="tag" key={tag}>
                      {tag}
                    </div>
                  ))}
                </div>
                <h2>{product.appTitle}</h2>
                <h5>{product.description}</h5>
                <div className="view-filter-wrapper">
                  <button
                    className="view-filter"
                    onClick={() => openInNewTab(product.appLink)}
                  >
                    {product.category
                      .map((category) => category.toLowerCase())
                      .find((category) => category.includes("filter"))
                      ? "View the Filter"
                      : "View"}
                  </button>
                  {product.needUniqueCode ? (
                    <button
                      className="unique-codes"
                      onClick={() => showUniqueCodes(product.appId)}
                    >
                      unique codes{" "}
                      <img src="./images/icon_arrow.png" alt="open" />{" "}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          )
        )
        ) : (
          <div className="not-found-message">No products found</div>
        )}
      </div>
          <div
            className={`unique-code-contianer ${
              isUniqueCodeVisible ? "show" : "hide"
            }`}
          >
            {uniqueCodes.map((uniqueCode) => (
              <div
                className="unique-code-item"
                onClick={(e) => onUniqueCodeCopy(e, uniqueCode)}
            key={uniqueCode}
              >
                {uniqueCode}
                <span>
                  <img src="./images/copy.png" alt="copy" />
                </span>
              </div>
            ))}
          </div>
          <div
            className="unique-code-contianer-close"
            onClick={hideUniqueCodes}
            style={{
              display: isUniqueCodeVisible ? "flex" : "none"
            }}
          >
            <img src="./images/Vector (6).png" alt="close" />
          </div>
    </>
  );
}

export default withLoader(Home);
